<template>
  <user-settings-layout>
    <template v-slot:title> {{ $t("settings.user.general.appearence.title") }} </template>
    <template v-slot:content>
      <div class="bg-white shadow rounded-md">
        <div class="border-b">
          <div class="py-4 px-5">
            <h3 class="text-lg leading-6 font-bold text-gray-900">{{ $t("settings.user.security.security.successor.title") }}</h3>
            <p class="mt-1 text-xs text-gray-500">
              <i18n-t keypath="settings.user.security.security.successor.body-text" for="settings.user.security.security.successor.body-text-link">
                <a href="#" target="_blank">{{ $t("settings.user.security.security.successor.body-text-link") }}</a>
              </i18n-t>
            </p>
          </div>
        </div>
        <div class="py-4 px-5"></div>

        <div class="bg-gray-50 py-4 px-5 rounded-b-md text-right">
          <button type="submit" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save</button>
        </div>
      </div>
    </template>
  </user-settings-layout>
</template>

<script>
import UserSettingsLayout from "@/layouts/UserSettings.vue";
export default {
  components: {
    UserSettingsLayout,
  },
};
</script>

<style></style>
